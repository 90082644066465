import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

function AfasConnector({}) {
  const data = useStaticQuery(graphql`
    query {
      afas1: file(relativePath: { eq: "afas-connector-1.png" }) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      afas2: file(relativePath: { eq: "afas-connector-2.png" }) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      afas3: file(relativePath: { eq: "afas-connector-3.png" }) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO
        description={`AFAS Profit Connector for Google Sheets`}
        pathname={`/add-ons/afas-connector-for-google-sheets`}
        title={`AFAS Profit Connector`}
      />
      <Layout subtitle={`for Google Sheets`} title={`AFAS Profit Connector`}>
        <main className="bg-gladior-dark ">
          <section className="container mx-auto px-6 py-10  max-w-7xl prose  ">
            <h3>Overzicht</h3>

            <p className="text-base leading-6 text-gray-300 max-w-3xl">
              <a
                className="text-gladior-pink"
                href="https://github.com/Gladior-B-V/afas-connector-for-google-sheets/issues"
              >
                Report an issue
              </a>
              {` `}
              <br />
              <a className="text-gladior-pink" href="/contact">
                Support
              </a>
              {` `}
              <br />
              <a className="text-gladior-pink" href="/voorwaarden/">
                Terms and conditions
              </a>
            </p>

            <p>
              Werk jij voor een organisatie die AFAS Software gebruikt? En maak
              je gebruik van Profit Connectors? Gebruik dan AFAS Connector voor
              Google Sheets, de online tool voor het opvragen van connectoren
              voor klanten, partners en AFAS-consultants.
            </p>

            <h3>AFAS Google Sheets add-on installeren</h3>

            <ol>
              <li>
                Ga naar{" "}
                <a href="https://gsuite.google.com/marketplace/app/afas_connector_for_google_sheets/274906366740">
                  AFAS Profit Connector - G Suite Marketplace
                </a>
              </li>
              <li>
                Log in het Google account waar je de connector mee wil
                gebruiken.
              </li>
              <li>
                Autoriseer de connector toegang tot je Google Account zodat de
                connector gebruikt kan worden!
              </li>
            </ol>

            {/* <p>
              Tip! Je kan de AFAS Connector ook direct installeren vanuit je
              Google Sheet
            </p> */}

            <p>
              Wanneer je Add-on hebt geïnstalleerd is de connectie met de Profit
              omgeving makkelijk gemaakt. Kijk in je Google sheet onder Add-on
              en selecteer AFAS Connector → <code>Authorize</code>
            </p>

            <h3>Hoe werkt de AFAS Connector? </h3>

            <ol>
              <li>
                Login in met de AFAS Token. <code>AFAS Online member nr</code>.
                & de token verkrijg je via Profit. Lees hier hoe je deze
                bemachtigd.
              </li>

              <li>
                Via <code>Add-ons</code> → <code>AFAS Connector</code> →{" "}
                <code>Import GetConnector</code> → Kies eventuele filters en ga
                door naar <code>Execute & Import</code>.
              </li>
              <Img fixed={data.afas1.childImageSharp.fixed} />
              <li>
                Nu heb je toegang tot de data van de ingerichte GetConnector. De
                data die je hebt kan je met een zelf gekozen interval opnieuw
                ophalen zodat de data up-to-date is. Interval selecteren doe je
                via <code>Add-ons</code> → <code>AFAS Connector</code> →{" "}
                <code>Schedule execute GetConnector</code>.
              </li>
              <Img fixed={data.afas3.childImageSharp.fixed} />
            </ol>

            <h3>Voordelen van de AFAS Connector</h3>
            <ul>
              <li>Geen gecompliceerde code</li>
              <li>Direct data in Google Sheets.</li>
              <li>
                Makkelijke koppeling tot Dashboards, zoals Data Studio & Power
                BI
              </li>
            </ul>
          </section>
        </main>
      </Layout>
    </>
  );
}
export default AfasConnector;
